import React from "react";
import "./LandingPage.css";

const LandingPage: React.FC = () => {
  const customerCards = [
    {
      title: "Taxi Booking",
      description: "Book your taxi now with a click of a button.",
      image: "./taxi.png",
      buttonText: "Book Now",
      link: "/new-booking",
    },
    {
      title: "Ride Management",
      description: "Manage the details for your trip. No account needed.",
      image: "./history.png",
      buttonText: "Manage Existing",
      link: "/share-a-ride/booking",
    },
    {
      title: "Your Trusted Drivers",
      description: "Book your trusted drivers using their personal page.",
      image: "./trusted_partners.png",
      buttonText: "Book Polyvios Now",
      link: "/new-booking?client=taxi-dailytransfers",
    },
  ];

  const businessCards = [
    {
      title: "Ride Management",
      description: "Keep track of your bookings and respond to customers.",
      image: "./history.png",
      buttonText: "My Rides",
      link: "/client/ride-management",
    },
    {
      title: "Fleet Management",
      description: "Track and manage your fleet and drivers easily.",
      image: "./fleet.png",
      buttonText: "Manage Fleet",
      link: "/client/fleet-management",
    },
    {
      title: "Tariff Management",
      description: "Build your personal price list and area of operation.",
      image: "./area_of_operation.png",
      buttonText: "Manage Tariffs",
      link: "/client/tariff-management",
    },
  ];

  return (
    <div className="landing-page">
      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-content">
          <h1>Book with ease, travel with joy</h1>
          <p>
            Book your ride with Transfero and enjoy a journey led by trusted
            local drivers who know Athens best.
          </p>
          <a href="/new-booking" className="search-button">
            Search your driver
          </a>
        </div>
      </div>

      {/* Customer Cards */}
      <h2 className="section-title">Customer Services</h2>
      <div className="cards-container">
        {customerCards.map((card, index) => (
          <div key={index} className="card">
            <img
              src={require(`${card.image}`)}
              alt={card.title}
              className="card-image"
            />
            <h2 className="card-title">{card.title}</h2>
            <p className="card-description">{card.description}</p>
            {card.buttonText && (
              <a href={card.link} className="card-button">
                {card.buttonText}
              </a>
            )}
          </div>
        ))}
      </div>

      {/* Business Cards */}
      <h2 className="section-title">Business Services</h2>
      <div className="cards-container">
        {businessCards.map((card, index) => (
          <div key={index} className="card">
            <img
              src={require(`${card.image}`)}
              alt={card.title}
              className="card-image"
            />
            <h2 className="card-title">{card.title}</h2>
            <p className="card-description">{card.description}</p>
            {card.buttonText && (
              <a href={card.link} className="card-button">
                {card.buttonText}
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingPage;
