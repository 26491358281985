import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Message } from "../Account/types/Message";
import { AxiosError } from "axios";
import Severity from "../common/types/Severity";
import { getMessageContent } from "../Account/accountUtilities";
// import Button from "../common/Button";
// import SelectEnum from "../common/Select/SelectEnum";
import { formattedTime } from "@/utils/formatUtils";
import Passenger from "./Types/Passenger";
import { PaymentStatus } from "./Types/Status";
import "./ManageMyBooking.css";
import { patch } from "@/utils/apiUtils";

const App: React.FC = () => {
  const [message, setMessage] = useState<Message | null>(null);
  const [passenger, setPassenger] = useState<Passenger>();
  const myParam = useLocation().search;
  const [uuid] = useState<string|null>(new URLSearchParams(myParam).get("uuid"));
  // const [paymentStatus, setPaymentStatus] = useState<PaymentStatus|undefined>(PaymentStatus.UNPAID);
  // const [cashDeposit, setCashDeposit] = useState<number>();
  const [activeTab, setActiveTab] = useState<string>("booking"); // New state for tab navigation

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    // Fetch locations
    if (uuid) {
      patch(`/passengers/confirm/${uuid}`, {})
      .then((response) => {
        setPassenger(response.data.data);
        // setPaymentStatus(response.data.data.paymentStatus);
        setMessage({ message: "Your details have been confirmed.", severity: Severity.Info });
      })
      .catch((error: unknown)=>{
        if (error instanceof AxiosError) {
            setMessage({ message: error.response?.data.message, severity: Severity.Error });
          }
      });
    }
    // eslint-disable-next-line
  }, [uuid]);

  // const handlePaymentStatusSubmit = () => {
  //   if (paymentStatus !== passenger?.paymentStatus) {
  //     get(`/payments/passenger/${uuid}/${paymentStatus}`, {})
  //     .then((response) => {
  //       setPassenger(response.data.data);
  //       setMessage({ message: "Payment Status changed to: "+ paymentStatus, severity: Severity.Info });
  //       setPaymentStatus(response.data.data.paymentStatus);
  //     })
  //     .catch((error: unknown)=>{
  //       if (error instanceof AxiosError) {
  //           setMessage({ message: error.response?.data.message, severity: Severity.Error });
  //         }
  //     });
  //   } else {
  //     setMessage({ message: "Payment Status has not been changed. No action taken", severity: Severity.Warning });
  //   }
    
  // }

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  } if (!uuid) {
    let noUuidMessage: Message = {message: "Please provide a UUID to view booking details", severity: Severity.Warning};
    messageContent = getMessageContent(noUuidMessage);
  }

  // const handleSelectChange =
  // (enumObject: Record<string, string>, name: keyof Passenger) =>
  // (selectedOption: any) => {
  //   setPaymentStatus(selectedOption);

  //   if (selectedOption === PaymentStatus.CASH_TRANSFER_UNCONFIRMED) {
  //     get(`/payments/passenger/cashDeposit/${uuid}`)
  //     .then((response) => {
  //       setCashDeposit(response.data.data);
  //     })
  //     .catch((error: unknown)=>{
  //       if (error instanceof AxiosError) {
  //           setMessage({ message: error.response?.data.message, severity: Severity.Error });
  //         }
  //     });
  //   } else {
  //     setCashDeposit(undefined);
  //   }
  // };

  return (
    <div className="booking-container">
      {messageContent}
      {uuid && (
        <div>
          <div className="tabs">
            <button
              className={activeTab === "booking" ? "active" : ""}
              onClick={() => handleTabChange("booking")}
            >
              Booking Details
            </button>
            <button
              className={activeTab === "passenger" ? "active" : ""}
              onClick={() => handleTabChange("passenger")}
            >
              Passenger Details
            </button>
            <button
              className={activeTab === "ride" ? "active" : ""}
              onClick={() => handleTabChange("ride")}
            >
              Company Details
            </button>
            <button
              className={activeTab === "payment" ? "active" : ""}
              onClick={() => handleTabChange("payment")}
            >
              Payment Details
            </button>
          </div>

          <div className="tab-content">
            {activeTab === "booking" && passenger && (
              <div className="booking-details">
                {(passenger.ride?.hour < 23 || passenger.ride?.hour > 4) ? <p>
                  <b>Price:</b> {passenger.priceEuro} €
                </p>: <p>
                  <b>Night Price (23:00-05:00):</b> 
                  {passenger.ride?.tariff.nightPriceEuro ? 
                    passenger.ride.tariff.nightPriceEuro :
                    passenger.ride.tariff.priceEuro} €
                </p>}
                <p>
                  <b>Pick-up Location:</b> {passenger.ride?.destinationFrom?.location_name}
                </p>
                <p>
                  <b>Drop-off Location:</b> {passenger.ride?.destinationTo?.location_name}
                </p>
                <p>
                  <b>Date:</b> {passenger.ride?.date}
                </p>
                <p>
                  <b>Time:</b> {formattedTime(passenger.ride?.hour, passenger.ride?.minute)}
                </p>
                {(passenger.ride?.destinationFrom?.airportInd || passenger.ride?.destinationTo?.airportInd) && 
                  <div>
                    <p><b>Flight No: </b> <span>{passenger.ride?.flightNo}</span></p>
                  </div>}
                <div><b>Status:</b> <b><span>{passenger.ride?.status}</span></b></div>
                {passenger.ride?.status === "UNCONFIRMED" && <p className="description">*Status will change once the driver responds to your request. You will also receive an update via email.</p>}
              </div>
            )}

            {activeTab === "passenger" && passenger && (
              <div className="passenger-details">
                <p>
                  <b>First name:</b> {passenger.firstName}
                </p>
                <p>
                  <b>Number of People:</b> {passenger.people}
                </p>
                <p>
                  <b>Number of Luggage:</b> {passenger.luggage}
                </p>
              </div>
            )}

            {activeTab === "ride" && passenger && (
              <div className="ride-details">
                {/* Transfer Company Details */}
                <div className="section">
                  <h1>Your Transfer Company</h1>
                  <div>
                    <label>Company Name:</label> <span>{passenger.ride?.client.companyName}</span>
                  </div>
                  <div>
                    <label>Country:</label> <span>{passenger.ride?.client.country}</span>
                  </div>
                  <div>
                    <label>Address Line 1:</label> <span>{passenger.ride?.client.addressLine1}</span>
                  </div>
                  <div>
                    <label>Address Line 2:</label> <span>{passenger.ride?.client.addressLine2}</span>
                  </div>
                  <div>
                    <label>Postcode:</label> <span>{passenger.ride?.client.postcode}</span>
                  </div>
                  <div>
                    <label>Email:</label> <span>{passenger.ride?.client.email}</span>
                  </div>
                  <div>
                    <label>Phone:</label> <span>{passenger.ride?.client.phone}</span>
                  </div>
                </div>

                {/* Driver Details */}
                <div className="section">
                  <h1>Your Driver</h1>
                  {passenger.ride?.driver ? (
                    <div>
                      <label>Driver Name:</label>{" "}
                      <span>{passenger.ride.driver.firstName} {passenger.ride.driver.lastName}</span>
                    </div>
                  ) : (
                    <div className="pending">Pending...</div>
                  )}
                </div>

                {/* Vehicle Details */}
                <div className="section">
                  <h1>Vehicle Details</h1>
                  {passenger.ride?.vehicle ? (
                    <>
                      <div>
                        <label>Vehicle Type:</label> <span>{passenger.ride.vehicle.vehicleType}</span>
                      </div>
                      <div>
                        <label>Licence Plate:</label> <span>{passenger.ride.vehicle.licencePlate}</span>
                      </div>
                      <div>
                        <label>Make:</label> <span>{passenger.ride.vehicle.make}</span>
                      </div>
                      <div>
                        <label>Model:</label> <span>{passenger.ride.vehicle.model}</span>
                      </div>
                      <div>
                        <label>Maximum People:</label> <span>{passenger.ride.vehicle.maxPeople}</span>
                      </div>
                      <div>
                        <label>Maximum Luggage:</label> <span>{passenger.ride.vehicle.maxLuggage}</span>
                      </div>
                    </>
                  ) : (
                    <div className="pending">Pending...</div>
                  )}
                </div>
              </div>
            )}

            {activeTab === "payment" && passenger && (
              <div className="payment-details">
                {/* <p>
                  <b>Payment Status:</b> {passenger.paymentStatus}
                </p>
                <SelectEnum
                  selectedValue={paymentStatus}
                  enumObject={PaymentStatus}
                  onSelect={handleSelectChange(PaymentStatus, "paymentStatus")}
                />
                
                {cashDeposit && (
                  <div className="cash-deposit">
                    Cash Deposit Amount: {cashDeposit} € (+final amount in cash)
                  </div>
                )}
                
                <Button type="submit" onClick={handlePaymentStatusSubmit}>
                  Update Payment
                </Button> */}

                <p>
                  <b>Payment Status:</b>{" "}
                  {passenger.paymentStatus ===
                    PaymentStatus.CASH_TRANSFER_CONFIRMED ||
                  passenger.paymentStatus ===
                    PaymentStatus.BANK_TRANSFER_CONFIRMED ||
                  passenger.paymentStatus ===
                    PaymentStatus.PAID
                    ? "Paid"
                    : "Pending"}
                </p>

                <p className="payment-method">Payment Details</p>
                
                <ul>
                {passenger.ride?.client.paymentDetails && passenger.ride?.client.paymentDetails.length > 0 ? (
                <ul>
                  {passenger.ride.client.paymentDetails.map((paymentDetails) => (
                    <li key={paymentDetails.id}>
                      {paymentDetails.paymentMethod && <p><b>{paymentDetails.paymentMethod}</b></p>}
                      {paymentDetails.recipientName && <p>Recepient: {paymentDetails.recipientName}</p>}
                      {paymentDetails.bankName && <p>Bank: {paymentDetails.bankName}</p>}
                      {paymentDetails.iban && <p>IBAN: {paymentDetails.iban}</p>}
                      {paymentDetails.swiftCode && <p>Swift Code: {paymentDetails.swiftCode}</p>}
                      {paymentDetails.accountNumber && <p>Account No: {paymentDetails.accountNumber}</p>}
                      {paymentDetails.tag && <p>Tag: {paymentDetails.tag}</p>}
                      {paymentDetails.phoneNumber && <p>Phone Number: {paymentDetails.phoneNumber}</p>}
                    </li>
                  ))}
                </ul>
              ) : (
                <div>
                  <p>The client has not provided any payment details yet.</p>
                  <p>You will be able to pay by <b>cash/card</b> when your ride is completed.</p>
                </div>
              )}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
