import {delWithAuthHeader, getWithAuthHeader, patchWithAuthHeader } from '@utils/apiUtils';
import {LoginInfo, PII, PasswordInfo} from "./types/Account";
import { appendBackofficeURL } from '@/utils/authUtils';

export const getAccounts = async (ids: string) => {
  // TODO pass id dynamically
  try {
    const response = await getWithAuthHeader(appendBackofficeURL()+'/accounts/list?ids='+ids);
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAccount = async () => {
  try {
    const response = await getWithAuthHeader(appendBackofficeURL()+'/accounts');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updatePii = async (id: string, pii: PII) => {
  try {
    await patchWithAuthHeader('accounts/pii', pii);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const updateLogin = async (id: string, loginInfo: LoginInfo) => {
  try {
    await patchWithAuthHeader('accounts/login-info', loginInfo);
  } catch (error: unknown) {
    console.log(error);
    throw error;
  }
}

export const updatePassword = async (id: string, passwordInfo: PasswordInfo) => {
  try {
    await patchWithAuthHeader('accounts/password', passwordInfo);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const deleteAccountSoft = async () => {
  try {
    await delWithAuthHeader('accounts');
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// const delay = ms => new Promise(res => setTimeout(res, ms));