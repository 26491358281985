import { useEffect, useState } from "react";
import "./Login.css";
import { isAuthenticated, login, logout } from "@/utils/authUtils";
import { AxiosError } from "axios";
import Severity from "../common/types/Severity";
import { getMessageContent } from "../Account/accountUtilities";
import { Message } from "@components/Account/types/Message";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "@/utils/apiUtils";

interface IMyProps {
  setAuth?: Function;
}

const Login: React.FC<IMyProps> = (props: IMyProps) => {
  const myParam = useLocation().search;
  const [uuid] = useState<string | null>(new URLSearchParams(myParam).get("uuid"));
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [redirectLogout, setRedirectLogout] = useState(false);
  const [message, setMessage] = useState<Message>({});
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch activation status if `uuid` is present in the URL
    if (uuid) {
        get(`/auth/activate?code=${uuid}`)
        .then((response) => {
          setMessage({
            message: "Thanks for confirming your email. You can now continue creating your profile.",
            severity: Severity.Info,
          });
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  }, [uuid]);

  const handleLogout = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await logout(false);
      if (props.setAuth) props.setAuth(false);
      setRedirectLogout(true);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessage({
          message: error.response?.data.message,
          severity: Severity.Error,
        });
      }
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login(username, password);
      if (props.setAuth) props.setAuth(true);
      setMessage({});
      setRedirectLogin(true);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessage({
          message: error.response?.data.message,
          severity: Severity.Error,
        });
      }
    }
  };

  if (redirectLogin) {
     navigate("/client/ride-management");
    return null;
  } else if (redirectLogout) {
    navigate("/");
  } 
  else if (isAuthenticated()) {
    return (
      <div className="login-wrapper">
        <h2>Are you sure you want to logout?</h2>
        <form onSubmit={handleLogout}>
          <button type="submit">Logout</button>
        </form>
      </div>
    );
  }

  return (
    <form onSubmit={handleLogin} className="login-form">
      {message.message && getMessageContent(message)}
      <label>
        Username/Email
        <input
          type="text"
          placeholder="Enter your username or email"
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </label>
      <label>
        Password
        <input
          type="password"
          placeholder="Enter your password"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </label>
      <button type="submit">Login</button>
    </form>
  );
};

export default Login;
