import React, { ChangeEvent, useState } from "react";
import DriverDetail from "./DriverDetail";
import "./DriverList.css";
import { Driver } from "../../Types/Vehicle";
import Modal from "@/components/Modal/Modal";
import { AxiosError } from "axios";
import DriverItem from "./DriverItem";
import {
  delWithAuthHeader,
  patchWithAuthHeader,
  postWithAuthHeader,
} from "@/utils/apiUtils";
import Button from "@/components/common/Button";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import { ActionProps } from "../../Types/ActionProps";
import { ButtonType } from "../../Types/ButtonType";

const APPEND_URL = "/client/fleet/driver";

interface IMyProps {
  items: Driver[] | undefined;
  refresh: Function;
}

const initDriver: Driver = {
  firstName: "",
  lastName: "",
};

const DriverList: React.FC<IMyProps> = (props: IMyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [driver, setDriver] = useState<Driver>(initDriver);
  const [message, setMessage] = useState<Message>();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setDriver(initDriver);
    setIsOpen(false);
    setMessage(undefined);
  };

  const handleSelect = (item: Driver) => {
    console.log(item);
    setDriver(item);
    openModal();
  };

  const handleNew = (driver: Driver) => {
    setDriver(initDriver);
    openModal();
  };

  const handleDelete = async () => {
    if (driver && driver.id) {
      await delWithAuthHeader(`${APPEND_URL}/${driver.id}`)
        .then((response) => {
          if (response.data.data === true) {
            closeModal();
            props.refresh(false, driver.id);
          }
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const deleteAction: ActionProps = {
    name: "Delete",
    method: handleDelete,
    buttonType: ButtonType.delete,
  };

  const handleUpdate = async () => {
    console.log(driver);
    if (driver && driver.id) {
      await patchWithAuthHeader(`${APPEND_URL}`, driver)
        .then(() => {
          props.refresh(true);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const updateAction: ActionProps = {
    name: "Update",
    method: handleUpdate,
    buttonType: ButtonType.primary,
  };

  const handleCreate = async () => {
    console.log(driver);
    if (driver && driver.firstName !== "" && driver.lastName !== "") {
      await postWithAuthHeader(`${APPEND_URL}`, driver)
        .then(() => {
          props.refresh(true);
          setDriver(initDriver);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const createAction: ActionProps = {
    name: "Create",
    method: handleCreate,
    buttonType: ButtonType.primary,
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setDriver({
      ...driver,
      [e.target.name]: e.target.value,
    });
  };

  var actions: ActionProps[] = [createAction, updateAction, deleteAction];

  return (
    <div>
      <Button onClick={() => handleNew(driver)}>ADD</Button>
      <ul className="list-container">
        {props.items?.map((item: Driver) => (
          <DriverDetail
            key={item.id}
            details={item}
            handleSelect={handleSelect}
          />
        ))}
      </ul>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        actions={actions}
        title={"Driver Details"}
        message={message}
      >
        {driver && (
          <DriverItem item={driver} handleInputChange={handleInputChange} />
        )}
      </Modal>
    </div>
  );
};

export default DriverList;
