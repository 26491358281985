import { Message } from "./types/Message";
import Severity from "../common/types/Severity";
import "../../styles/Message.css";
import Alert from "@mui/material/Alert";

export const getMessageContent = (message: Message) => {
  switch (message.severity) {
    case Severity.Error:
      return (
        <div id={`${message.code}`}>
          <Alert severity="error">
            <div className="messageTitle">{Severity.Error}</div>
            {message.message}
          </Alert>
        </div>
      );
    case Severity.Warning:
      return (
        <div id={`${message.code}`}>
          <Alert severity="warning">
            <div className="messageTitle">{Severity.Warning}</div>
            {message.message}
          </Alert>
        </div>
      );
    case Severity.Info:
      return (
        <div id={`${message.code}`}>
          <Alert severity="info">
            <div className="messageTitle">{Severity.Info}</div>
            {message.message}
          </Alert>
        </div>
      );
    default:
      return (
        <p className="message" id={`${message.code}`}>
          {message.message}
        </p>
      );
  }
};
