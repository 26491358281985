// App.tsx
import { getMessageContent } from "@/components/Account/accountUtilities";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { Driver, Vehicle } from "../Types/Vehicle";
import VehicleList from "./Vehicle/VehicleList";
import DriverList from "./Driver/DriverList";
import { getWithAuthHeader } from "@/utils/apiUtils";

const APPEND_URL = "/client/fleet";

const typeVehicles = 'vehicles';
const typeDrivers = 'drivers';

const App: React.FC = () => {
  
  const [message, setMessage] = useState<Message | null>(null);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  }

  const refresh = (type: string) => {
    getWithAuthHeader(`${APPEND_URL}/${type}`)
    .then((response) => {
      console.log(response.data.data);
      if (type === typeVehicles) {
        setVehicles(response.data.data);
      } else if (type === typeDrivers) {
        setDrivers(response.data.data);
      }
    })
    .catch((error: unknown)=>{
      if (error instanceof AxiosError) {
          setMessage({ message: error.response?.data.message, severity: Severity.Error });
        }
    });
  }

  useEffect(() => {
      refresh(typeVehicles);
      refresh(typeDrivers);
    // eslint-disable-next-line
    }, []);

    const refreshDrivers = (refreshAll: boolean, removeId?: number) => {
      if (refreshAll) {
        refresh(typeDrivers);
      } else if (removeId) {
        setDrivers(drivers.filter((driver)=>driver.id !== removeId));
      }
    }

    const refreshVehicles = (refreshAll: boolean, removeId?: number) => {
      if (refreshAll) {
        refresh(typeVehicles);
      } else if (removeId) {
        setVehicles(vehicles.filter((vehicle)=>vehicle.id !== removeId));
      }
    }

  return (
    <div>
      {messageContent}
      <h1>Fleet Management (Taxi - Daily Transfers)</h1>
      <div>
      <h1 className="text-3xl font-bold">Vehicles {(vehicles && vehicles?.length > 0) && "- "+ vehicles.length}</h1>
      <VehicleList items={vehicles} refresh={refreshVehicles} drivers={drivers}/>
    </div>

      <div>
      <h1 className="text-3xl font-bold">Drivers {(drivers && drivers?.length > 0) && "- "+ drivers.length}</h1>
      <DriverList items={drivers} refresh={refreshDrivers}/>
    </div>
    </div>
  );
};

export default App;
