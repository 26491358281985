import { appendBackofficeURL } from '@/utils/authUtils';
import { delWithAuthHeader, getWithAuthHeader, patchWithAuthHeader, postWithAuthHeader } from '../../utils/apiUtils';
import AddressDetails from './types/AddressDetails';

export const getAddresses = async (scope: string|undefined) => {
  try {
    const response = await getWithAuthHeader(appendBackofficeURL()+'/addresses?scope='+ scope);
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addAddress = async (addressDetails: AddressDetails) => {
  try {
    const response = await postWithAuthHeader(appendBackofficeURL()+'/addresses', addressDetails);
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const patchAddress = async (addressDetails: AddressDetails) => {
  try {
    const response = await patchWithAuthHeader(appendBackofficeURL()+'/addresses', addressDetails);
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteAddress = async (id: number) => {
  try {
    const response = await delWithAuthHeader(appendBackofficeURL()+'/addresses/'+id);
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

