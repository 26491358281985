import { Authority, isAuthenticated, tokenHasAuthority } from '@/utils/authUtils';
import Login from '@/components/LoginRegister/LoginRegister';
import { useEffect, useState } from 'react';

interface AuthorizedProps {
    setAuth?: Function;
    requiredAuthority: Authority
  }

const withAuthorization = <P extends AuthorizedProps>(
    WrappedComponent: React.ComponentType<P>
  ) => (props: P) => {
    const [authorized, setAuthorized] = useState(isAuthenticated);
  
    useEffect(() => {
        if (props.setAuth !== undefined) props.setAuth(isAuthenticated());
    }, [props, authorized]);

    if (!isAuthenticated()  || !tokenHasAuthority(props.requiredAuthority)) {
        return <Login setAuth={setAuthorized}/>
    }
  
    return <WrappedComponent {...props} />;
  }

  export default withAuthorization;
