import React, { useState } from "react";
import "./ClientList.css"; // Assuming styles are added here
import logo from "./logo512.png"; // Default fallback image

export interface ClientAbstract {
  uniqueIdentifier: string;
  companyName: string;
}

interface ClientListProps {
  clients: ClientAbstract[];
}

const ClientList: React.FC<ClientListProps> = ({ clients }) => {
  const [filter, setFilter] = useState<string>("");

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value.toLowerCase());
  };

  // Helper function to get the image path or fallback to the logo
  const getClientImage = (clientIdentifier: string): string => {
    try {
      return require(`@common/${clientIdentifier}/${clientIdentifier}.png`);
    } catch {
      return logo; // Default fallback image
    }
  };

  const filteredClients = clients.filter(
    (client) =>
      client.uniqueIdentifier.toLowerCase().includes(filter) ||
      client.companyName.toLowerCase().includes(filter)
  );

  return (
    <div>
      {clients.length > 0 ? (
        <div>
          <p className="text">
            Currently, this functionality is only available for specific
            clients. Please use a client's unique link to access the
            application.
          </p>
          <input
            type="text"
            placeholder="Search by client name or unique identifier"
            value={filter}
            onChange={handleFilterChange}
            className="filter-input"
          />
          <div className="client-grid">
            {filteredClients.map((client) => (
              <div key={client.uniqueIdentifier} className="client-card">
                <img
                  src={getClientImage(client.uniqueIdentifier)}
                  alt={client.companyName}
                  className="client-image"
                />
                <div className="client-details">
                  <h3>{client.companyName}</h3>
                  <a href={`/new-booking?client=${client.uniqueIdentifier}`}
                  className="book-button">
                    Book Now
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className="text">No Clients Available right now. Please try again later.</p>
      )}
    </div>
  );
};

export default ClientList;
