import jwtDecode from "jwt-decode";
import { getAccounts } from "../components/Account/accountService";
import { getWithAuthHeader, post } from "./apiUtils";
import { Account } from "@/components/Account/types/Account";

const APPEND_BACKOFFICE_URL = "backoffice";
interface DecodedToken {
  sub: string;
  authorities: Authority[];
  iat: number;
  exp: number;
}

export enum Authority {
  ROLE_SUPEERUSER = "ROLE_SUPEERUSER",
  ROLE_BACKOFFICE = "ROLE_BACKOFFICE",
}

export const getAuthHeader = () => {
  const tokenStr = sessionStorage.getItem("token");

  if (tokenStr !== null) {
    let sessionToken = JSON.parse(tokenStr);
    let bearerStr = "Bearer " + sessionToken.token;
    const headers = {
      Authorization: bearerStr,
    };
    return headers;
  } else {
    return undefined;
  }
};

export const getAuthToken = () => {
  const tokenStr = sessionStorage.getItem("token");

  if (tokenStr !== null) {
    let sessionToken = JSON.parse(tokenStr);
    return sessionToken.token;
  }

  return null;
};

export const isBackoffice = () => {
  return tokenHasAuthority(Authority.ROLE_BACKOFFICE) || tokenHasAuthority(Authority.ROLE_SUPEERUSER);
}

export const appendBackofficeURL = () => {
  return isBackoffice() ? APPEND_BACKOFFICE_URL : "";
}

export const tokenHasAuthority = (authority: Authority) => {
  const token = getAuthToken();
  if (token !== null) {
    return hasAuthority(jwtDecode(token), authority);
  }

  return false;
};

export const isAuthenticated = () => {
  const userToken = getAuthToken();
  if (userToken === null) {
    localStorage.clear();
    sessionStorage.clear();
    return false;
  }

  let decodedToken: DecodedToken = jwtDecode(userToken);
  let currentDate = new Date();

  // JWT exp is in seconds
  if (
    decodedToken.exp != null &&
    decodedToken.exp * 1000 < currentDate.getTime()
  ) {
    localStorage.clear();
    sessionStorage.clear();
    return false;
  }

  return true;
};

export const getUsername = () => {
  const userToken = getAuthToken();
  if (userToken === null) {
    return null;
  }
  let decodedToken: DecodedToken = jwtDecode(userToken);
  // JWT exp is in seconds
  if (
    decodedToken.sub != null
  ) {
    return decodedToken.sub;
  }

  return "Anonymous";
};

export const hasAuthority = (
  decodedToken: DecodedToken,
  requiredAuthority: Authority
) => {
  return decodedToken.authorities.includes(requiredAuthority);
};

export const accountLoginTEST = async (id: string) => {
  try {
    const accounts = await getAccounts(id);
    if (accounts !== null || accounts.length !== 0) {
      localStorage.setItem("account", JSON.stringify(accounts[0]));
    }
    return accounts[0];
  } catch (error) {
    throw error;
  }
};

export const login = async (
  username: string | undefined,
  password: string | undefined
) => {
  try {
    const token = await post("auth/login", {
      identification: username,
      password: password,
    });
    sessionStorage.setItem("token", JSON.stringify(token.data));
    return token;
  } catch (error) {
    throw error;
  }
};

export const logout = async (removeTokenOnly: boolean) => {
  try {
    if (!removeTokenOnly) {
      const authHeader = getAuthHeader();
      await post("auth/logout", undefined, authHeader);
    }
    sessionStorage.removeItem("token");
    localStorage.clear();
    sessionStorage.clear();
  } catch (error) {
    throw error;
  }
};

export const register = async (account: Account) => {
  try {
    const response = await post('auth/register', account);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const hasAssociatedClient = async () => {

  const response = await getWithAuthHeader("/client");
  console.log(response.data.data);
    if (response.data.data && response.data.data.id) {
      console.log("client found");
      return true;
    }

    console.log("client not  found");
  return false;
}
