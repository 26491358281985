import React, { ChangeEvent, useState } from "react";
import VehicleDetail from "./VehicleDetail";
import "./VehicleList.css";
import { Driver, Vehicle } from "../../Types/Vehicle";
import Modal from "@/components/Modal/Modal";
import { AxiosError } from "axios";
import VehicleItem from "./VehicleItem";
import {
  delWithAuthHeader,
  patchWithAuthHeader,
  postWithAuthHeader,
} from "@/utils/apiUtils";
import Button from "@/components/common/Button";
import { ActionProps } from "../../Types/ActionProps";
import { ButtonType } from "../../Types/ButtonType";

const APPEND_URL = "/client/fleet";

interface IMyProps {
  items: Vehicle[] | undefined;
  refresh: Function;
  drivers?: Driver[];
}

const initVehicle = {
  licencePlate: "",
  maxLuggage: 3,
  maxPeople: 3,
  vehicleType: "TAXI",
  make: "",
  model: "",
  driver: null,
};

const VehicleList: React.FC<IMyProps> = (props: IMyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [vehicle, setVehicle] = useState<Vehicle>(initVehicle);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setVehicle(initVehicle);
    setIsOpen(false);
  };

  const handleSelect = (vehicle: Vehicle) => {
    setVehicle(vehicle);
    openModal();
  };

  const handleDriverOptionChange = (e: number) => {
    console.log(e);
    var driverLocal = props.drivers?.filter(
      (tariff) => tariff.id === Number(e)
    )[0];

    setVehicle({
      ...vehicle,
      driver: driverLocal ? driverLocal : null,
    });
  };

  const handleDelete = async () => {
    if (vehicle) {
      await delWithAuthHeader(`${APPEND_URL}/vehicle/${vehicle.id}`)
        .then((response) => {
          if (response.data.data === true) {
            closeModal();
            props.refresh(false, vehicle.id);
          }
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            // setMessage({ message: error.response?.data.message, severity: Severity.Error });
          }
        });
    }
  };

  const deleteAction: ActionProps = {
    name: "Delete",
    method: handleDelete,
    buttonType: ButtonType.delete,
  };

  const handleUpdate = async () => {
    console.log(vehicle);
    if (vehicle && vehicle.id) {
      await patchWithAuthHeader(`${APPEND_URL}/vehicle`, vehicle)
        .then(() => {
          props.refresh(true);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            // setMessage({ message: error.response?.data.message, severity: Severity.Error });
          }
        });
    }
  };

  const updateAction: ActionProps = {
    name: "Update",
    method: handleUpdate,
    buttonType: ButtonType.primary,
  };

  const handleCreate = async () => {
    console.log(vehicle);
    if (vehicle && vehicle.licencePlate !== "" && vehicle.maxPeople >= 1) {
      await postWithAuthHeader(`${APPEND_URL}/vehicle`, vehicle)
        .then(() => {
          props.refresh(true);
          setVehicle(initVehicle);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            // setMessage({ message: error.response?.data.message, severity: Severity.Error });
          }
        });
    }
  };

  const createAction: ActionProps = {
    name: "Create",
    method: handleCreate,
    buttonType: ButtonType.primary,
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setVehicle({
      ...vehicle,
      [e.target.name]: e.target.value,
    });
  };

  var actions: ActionProps[] = [createAction, updateAction, deleteAction];

  return (
    <div>
      <ul className="list-container">
        <Button onClick={() => handleSelect(vehicle)}>ADD</Button>
        {props.items?.map((vehicle: Vehicle) => (
          <VehicleDetail
            key={vehicle.id}
            details={vehicle}
            handleSelect={handleSelect}
          />
        ))}
      </ul>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        actions={actions}
        title={"Vehicle Details"}
      >
        {vehicle && (
          <VehicleItem
            item={vehicle}
            handleInputChange={handleInputChange}
            drivers={props.drivers}
            handleDriverOptionChange={handleDriverOptionChange}
          />
        )}
      </Modal>
    </div>
  );
};

export default VehicleList;
