import React, { ChangeEvent, useState } from "react";
import TariffDetail from "./TariffDetail";
import "./TariffList.css";
import { Location, Tariff } from "../../Types/Tariff";
import Modal from "@/components/Modal/Modal";
import { AxiosError } from "axios";
import TariffItem from "./TariffItem";
import {
  delWithAuthHeader,
  patchWithAuthHeader,
  postWithAuthHeader,
} from "@/utils/apiUtils";
import Button from "@/components/common/Button";
import { ActionProps } from "../../Types/ActionProps";
import { ButtonType } from "../../Types/ButtonType";

const APPEND_URL = "/client/tariffs";

interface IMyProps {
  items: Tariff[] | undefined;
  refresh: Function;
  fromLocations?: Location[];
  toLocations?: Location[];
}

const initTariff = {
  priceEuro: undefined,
  fromLocation: null,
  toLocation: null,
};

const TariffList: React.FC<IMyProps> = (props: IMyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tariff, setTariff] = useState<Tariff>(initTariff);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setTariff(initTariff);
    setIsOpen(false);
  };

  const handleSelect = (tariff: Tariff) => {
    setTariff(tariff);
    openModal();
  };

  const handleFromOptionChange = (e: number) => {
    console.log(e);
    var fromLocationLocal = props.fromLocations?.filter(
      (location) => location.id === Number(e)
    )[0];

    setTariff({
      ...tariff,
      fromLocation: fromLocationLocal ? fromLocationLocal : null,
    });
  };

  const handleToOptionChange = (e: number) => {
    console.log(e);
    var toLocationLocal = props.toLocations?.filter(
      (location) => location.id === Number(e)
    )[0];

    setTariff({
      ...tariff,
      toLocation: toLocationLocal ? toLocationLocal : null,
    });
  };

  const handleDelete = async () => {
    if (tariff) {
      await delWithAuthHeader(`${APPEND_URL}/${tariff.id}`)
        .then((response) => {
          if (response.data.data === true) {
            closeModal();
            props.refresh(false, tariff.id);
          }
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            // setMessage({ message: error.response?.data.message, severity: Severity.Error });
          }
        });
    }
  };

  const deleteAction: ActionProps = {
    name: "Delete",
    method: handleDelete,
    buttonType: ButtonType.delete,
  };

  const handleUpdate = async () => {
    console.log(tariff);
    if (tariff && tariff.id) {
      await patchWithAuthHeader(`${APPEND_URL}`, tariff)
        .then(() => {
          props.refresh(true);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            // setMessage({ message: error.response?.data.message, severity: Severity.Error });
          }
        });
    }
  };

  const updateAction: ActionProps = {
    name: "Update",
    method: handleUpdate,
    buttonType: ButtonType.primary,
  };

  const handleCreate = async () => {
    console.log(tariff);
    if (
      tariff &&
      tariff.fromLocation !== null &&
      tariff.toLocation !== null &&
      tariff.priceEuro !== undefined &&
      tariff.priceEuro > 0
    ) {
      await postWithAuthHeader(`${APPEND_URL}`, tariff)
        .then(() => {
          props.refresh(true);
          setTariff(initTariff);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            // setMessage({ message: error.response?.data.message, severity: Severity.Error });
          }
        });
    }
  };

  const createAction: ActionProps = {
    name: "Create",
    method: handleCreate,
    buttonType: ButtonType.primary,
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setTariff({
      ...tariff,
      [e.target.name]: e.target.value,
    });
  };

  var actions: ActionProps[] = [createAction, updateAction, deleteAction];

  return (
    <div>
      <ul className="list-container">
        <Button onClick={() => handleSelect(tariff)}>ADD</Button>
        {props.items?.map((tariff: Tariff) => (
          <TariffDetail
            key={tariff.id}
            details={tariff}
            handleSelect={handleSelect}
          />
        ))}
      </ul>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        actions={actions}
        title={"Tariff Details"}
      >
        {tariff && (
          <TariffItem
            item={tariff}
            handleInputChange={handleInputChange}
            fromLocation={props.fromLocations}
            handleFromLocationChange={handleFromOptionChange}
            toLocation={props.toLocations}
            handleToLocationChange={handleToOptionChange}
          />
        )}
      </Modal>
    </div>
  );
};

export default TariffList;
