import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { getMessageContent } from '../Account/accountUtilities';
import { Account } from '../Account/types/Account';
import { Message } from '../Account/types/Message';
import Severity from '../common/types/Severity';
import { register } from '@/utils/authUtils';

const Register: React.FC = () => {
  const [formData, setFormData] = useState<Account>({
    id: '',
    username: '',
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    // TODO: Remove default value for active and attribute from here as should not be required
    active: true,
    clientInd: true
  });

  const [message, setMessage] = useState<Message|null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Check if the password and confirmPassword fields match
    if (formData.password !== confirmPassword) {
      setMessage({message: 'Password and Confirm Password do not match.', severity: Severity.Error});

      return;
    }

    try{
        await register(formData);
        setMessage({message: "CREATED", severity: Severity.Info, code: undefined });
     } catch(error: unknown) {
        if (error instanceof AxiosError) {
            setMessage({message: error.response?.data.message, severity: Severity.Error, code: error.response?.data.code });
            console.log(error);
        }
     }
    
  };

  let messageContent;
  if (message !== null) {
      messageContent = getMessageContent(message);
  }

  return (
    <div>
      {messageContent}
      <form onSubmit={handleSubmit}>
        <label>
          First Name
          <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
        </label>
        <label>
          Last Name
          <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
        </label>
        <label>
          Email
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </label>
        <label>
          Username
          <input type="text" name="username" value={formData.username} onChange={handleChange} />
        </label>
        <label>
          Password
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </label>
        <label>
          Confirm Password
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </label>
        <button type="submit">Create Account</button>
      </form>
    </div>
  );
};

export default Register;
