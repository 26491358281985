import { isMobile } from "@/utils/globalFuncs";
import Button from "@common/Button";
import { RiCloseLine } from "react-icons/ri";

const ScheduleDetails: React.FC<{
  isScheduleOpen?: boolean;
  setScheduleOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isScheduleOpen, setScheduleOpen }) => {
  return (
    <div
      className={`scheduler-container ${
        isScheduleOpen ? "mobile" : isMobile() ? "hidden" : ""
      }`}
    >
      <RiCloseLine
        style={{ marginBottom: "-3px" }}
        className="close-icon"
        onClick={() => (setScheduleOpen ? setScheduleOpen(false) : {})}
      />
      <div className="schedule-title">Your Schedule</div>
      <div className="schedule-info">
        Keep your schedule flexible and organized. Start managing your time
        today!
      </div>
      <div className="statistics">
        <div className="statistics-title">Your Statistics:</div>
        <div className="statistics-info">
          <div className="statistics-info-detail">
            <div>
              <b>Total rides this week:</b>
            </div>
            <div> ...</div>
          </div>
          <div className="statistics-info-detail">
            <div>
              <b>Hours worked:</b>{" "}
            </div>
            <div> ...</div>
          </div>
          <div className="statistics-info-detail">
            <div>
              <b>Total earnings:</b>
            </div>
            <div> ...</div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <Button type="decline">
          <b>Calendar view</b>
        </Button>
      </div>
    </div>
  );
};

export default ScheduleDetails;
