import React, { ChangeEventHandler } from 'react';
import './SignUp.css';

interface IMyProps {
  email: string,
  handleInputChange: ChangeEventHandler
}

const SignUp: React.FC<IMyProps> = (props:IMyProps) => {

  return (
  <div>
      <input type="email" name="email" value={props.email} onChange={props.handleInputChange} placeholder='Email'/>
      <p><i className='bookATrip'>*Your email will not be shared with anyone.</i></p> 
      <p><i>We will only use it to communicate new features of our platform.</i></p>
  </div>
)};

export default SignUp;