import { useAppDispatch, useAppSelector} from '@store/hooks';
import LoginInfo from './LoginInfo';
import Password from './Password';
import PII from './PII';
import { fetchAccountAsync, selectAccount} from './accountSlice';
import { useEffect, useState } from 'react';
import { Message } from './types/Message';
import { getMessageContent } from './accountUtilities';
import Login from '../LoginRegister/LoginRegister';
import DeleteAccount from './DeleteAccount';

const AccountSettings = () => {
  const account = useAppSelector(selectAccount);
  const [infoUpdated, setInfoUpdated] = useState(false);
  const [message, setMessage] = useState<Message|null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (infoUpdated || account == null) {
      console.log("Info updated");
      dispatch(fetchAccountAsync());
      setInfoUpdated(false);
    }
  }, [infoUpdated, dispatch, account]);

  const infoUpdateHandler = (updated: boolean) => {
    setInfoUpdated(updated);
  }

  const messageHandler = (message: Message) => {
    setMessage(message);
  }

  let messageContent;
  if (message !== null) {
      messageContent = getMessageContent(message);
  }

  if (account !== null) {
    return (
      <div>
        <h1>Account Settings</h1>
        {messageContent}
        <LoginInfo 
          account={account} 
          infoUpdateHandler={infoUpdateHandler} 
          messageHandler={messageHandler}/>
        <Password
          account={account} 
          infoUpdateHandler={infoUpdateHandler} 
          messageHandler={messageHandler}/>
        <PII
          account={account} 
          infoUpdateHandler={infoUpdateHandler} 
          messageHandler={messageHandler}/>
          <DeleteAccount
          account={account} 
          infoUpdateHandler={infoUpdateHandler} 
          messageHandler={messageHandler}/>
        </div>
        
    )
  } else {
    return (<Login setAuth={() => infoUpdateHandler(true)}/>)
  }

}

export default AccountSettings;