import React, { ChangeEvent, useState } from "react";
import { RiZoomInFill } from "react-icons/ri";
import "./RideWithUs.css";
import Modal from "../Modal/Modal";
import SignUp from "./SignUp/SignUp";
import { Message } from "../Account/types/Message";
import Severity from "../common/types/Severity";
import { AxiosError } from "axios";
import { post } from "@/utils/apiUtils";
import { ActionProps } from "../ShareARide/Types/ActionProps";
import { ButtonType } from "../ShareARide/Types/ButtonType";

const RideWithUs: React.FC = () => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState<Message>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState<string>("");
  const [isSignedUp, setIsSignedUp] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleSignUpModal = () => {
    setIsSignUpModalOpen(!isSignUpModalOpen);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSignUpEmail(e.target.value);
  };

  const businessBenefits = [
    {
      title: "No Commission",
      description:
        "Our system will be subscription based so you get to keep 100% of your earnings. No commission fees.",
      image: "./drivers.png",
    },
    {
      title: "Ride Management",
      description:
        "Keep track of your bookings and respond to customers with ease.",
      image: "./history.png",
    },
    {
      title: "Fleet Management",
      description: "Track and manage your fleet and drivers effortlessly.",
      image: "./fleet.png",
    },
    {
      title: "Tariff Management",
      description: "Set your pricing and areas of operation with flexibility.",
      image: "./area_of_operation.png",
    },
  ];

  const handleSignUp = async () => {
    post(`/newsletter`, { email: signUpEmail })
      .then((response) => {
        setIsSignedUp(true);
        setModalMessage({
          message:
            "Thank you for signing up. We will keep you updated with the latest features.",
          severity: Severity.Info,
        });
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          setModalMessage({
            message: error.response?.data.message,
            severity: Severity.Error,
          });
        }
      });
  };

  const signUpAction: ActionProps = {
    name: "Sign Up",
    method: handleSignUp,
    buttonType: ButtonType.secondary,
    disabled: isSignedUp,
  };

  return (
    <div className="ride-with-us-page">
      {/* Hero Section */}
      <div className="driver-hero-section">
        <div className="hero-content">
          <h1>Drive with Transfero</h1>
          <p>
            Join our platform and grow your customer base with unique tools for
            success.
          </p>
          <a
            href="#ridewithus"
            onClick={toggleSignUpModal}
            className="hero-button"
          >
            Sign Up To Learn More
          </a>
          <a href="/register" className="hero-button">
            Start Your 2 Month Free Trial
          </a>
          <h2>
            **<u>PROMO</u>: The first 50 drivers to sign up will get an extra 2
            month free trial.**
          </h2>
        </div>
      </div>

      {/* Unique Selling Point Section */}
      <div className="unique-selling-point">
        <h2 className="section-title">Build Your Own Customer Network</h2>
        <p className="usp-description">
          With Transfero, each driver gets a <b>Personal Booking Page</b> where
          customers can book <b>directly with you.</b>
        </p>
        <p className="usp-description-italic">
          <i>
            Create a loyal network of customers who trust your service for their
            transfers.
          </i>
        </p>

        <div>
          <p className="usp-example-text">
            Have a closer look below <RiZoomInFill />
          </p>
          <img
            src={require("./booking_page_eg_long.png")}
            alt="Personal Booking Page Example"
            className="usp-image"
            onClick={toggleModal}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>

      {/* Business Benefits Section */}
      <h2 className="section-title">Why Drive with Us?</h2>
      <div className="cards-container">
        {businessBenefits.map((benefit, index) => (
          <div key={index} className="card">
            <img
              src={require(`${benefit.image}`)}
              alt={benefit.title}
              className="card-image"
            />
            <h2 className="card-title">{benefit.title}</h2>
            <p className="card-description">{benefit.description}</p>
          </div>
        ))}
      </div>

      {/* Modal for Image Preview */}
      {isModalOpen && (
        <div className="ride-with-us-modal" onClick={toggleModal}>
          <div
            className="ride-with-us-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={require("./booking_page_eg.png")}
              alt="Personal Booking Page Example"
              className="ride-with-us-modal-image"
            />
            <button className="close-button" onClick={toggleModal}>
              Close
            </button>
          </div>
        </div>
      )}

      <Modal
        isOpen={isSignUpModalOpen}
        onClose={toggleSignUpModal}
        actions={[signUpAction]}
        title={"Sign Up To Learn More"}
        message={modalMessage}
      >
        <SignUp email={signUpEmail} handleInputChange={handleInputChange} />
      </Modal>
    </div>
  );
};

export default RideWithUs;
