import React, { useState } from "react";
import Login from "@components/Login/Login";
import "./LoginRegister.css";
import Register from "@components/Register/Register";
import { isAuthenticated } from "@/utils/authUtils";

interface IMyProps {
  setAuth?: Function;
  tab?: "login" | "register";
}

const LoginRegister: React.FC<IMyProps> = (props: IMyProps) => {
  const [activeTab, setActiveTab] = useState<"login" | "register">(props.tab || "login");

  return (
    <div className="login-register-wrapper">
      <div className="tab-header">
      {!isAuthenticated() &&<button
          className={`tab-button ${activeTab === "login" ? "active" : ""}`}
          onClick={() => setActiveTab("login")}
        >
          Driver Login
        </button>}
        {!isAuthenticated() && <button
          className={`tab-button ${activeTab === "register" ? "active" : ""}`}
          onClick={() => setActiveTab("register")}
        >
          Driver Register
        </button>}
      </div>
      <div className="tab-content">
        {activeTab === "login" && <Login setAuth={props.setAuth}/>}
        {!isAuthenticated() && activeTab === "register" && <Register />}
      </div>
    </div>
  );
};

export default LoginRegister;
