import React, { ChangeEvent, useState } from "react";
import Detail from "./Detail";
import "./List.css";
import { Location } from "../../Types/Tariff";
import Modal from "@/components/Modal/Modal";
import { AxiosError } from "axios";
import Item from "./Item";
import {
  delWithAuthHeader,
  patchWithAuthHeader,
  postWithAuthHeader,
} from "@/utils/apiUtils";
import Button from "@/components/common/Button";
import { ActionProps } from "../../Types/ActionProps";
import { ButtonType } from "../../Types/ButtonType";

const APPEND_URL = "/client/locations";

interface IMyProps {
  items: Location[] | undefined;
  refresh: Function;
  fromLocations?: Location[];
  toLocations?: Location[];
}

const initLocation = {
  location_name: "",
  airportInd: false,
};

const List: React.FC<IMyProps> = (props: IMyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useState<Location>(initLocation);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setLocation(initLocation);
    setIsOpen(false);
  };

  const handleSelect = (location: Location) => {
    setLocation(location);
    openModal();
  };

  const handleDelete = async () => {
    if (location) {
      await delWithAuthHeader(`${APPEND_URL}/${location.id}`)
        .then((response) => {
          if (response.data.data === true) {
            closeModal();
            props.refresh(false, location.id);
          }
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            // setMessage({ message: error.response?.data.message, severity: Severity.Error });
          }
        });
    }
  };

  const deleteAction: ActionProps = {
    name: "Delete",
    method: handleDelete,
    buttonType: ButtonType.delete,
  };

  const handleUpdate = async () => {
    console.log(location);
    if (location && location.id) {
      await patchWithAuthHeader(`${APPEND_URL}`, location)
        .then(() => {
          props.refresh(true);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            // setMessage({ message: error.response?.data.message, severity: Severity.Error });
          }
        });
    }
  };

  const updateAction: ActionProps = {
    name: "Update",
    method: handleUpdate,
    buttonType: ButtonType.primary,
  };

  const handleCreate = async () => {
    console.log(location);
    if (
      location &&
      location.location_name !== null &&
      location.location_name.length > 0
    ) {
      await postWithAuthHeader(`${APPEND_URL}`, location)
        .then(() => {
          props.refresh(true);
          setLocation(initLocation);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            // setMessage({ message: error.response?.data.message, severity: Severity.Error });
          }
        });
    }
  };

  const createAction: ActionProps = {
    name: "Create",
    method: handleCreate,
    buttonType: ButtonType.primary,
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (name: string, value: boolean) => {
    setLocation({
      ...location,
      [name]: value,
    });
  };

  var actions: ActionProps[] = [createAction, updateAction, deleteAction];

  return (
    <div>
      <ul className="list-container">
        <Button onClick={() => handleSelect(location)}>ADD</Button>
        {props.items?.map((location: Location) => (
          <Detail
            key={location.id}
            details={location}
            handleSelect={handleSelect}
          />
        ))}
      </ul>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        actions={actions}
        title={"Location Details"}
      >
        {location && (
          <Item
            item={location}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}
      </Modal>
    </div>
  );
};

export default List;
