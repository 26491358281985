import React, { useEffect, useState } from "react";
import "./RideItem.css";
import { Ride } from "../Types/Ride";
import { Driver, Vehicle } from "../Types/Vehicle";
import SelectValue from "@/components/common/Select/SelectValue";
import { AxiosError } from "axios";
import { formattedTime, formattedTimestamp } from "@/utils/formatUtils";
import { getWithAuthHeader } from "@/utils/apiUtils";

interface IMyProps {
  ride: Ride;
  setClientResponse: Function;
  showOnlyClientDetails?: boolean;
}

const RideItem: React.FC<IMyProps> = (props: IMyProps) => {
  const [vehicle, setVehicle] = useState<Vehicle | undefined>(
    props.ride.vehicle
  );
  const [driver, setDriver] = useState<Driver | undefined>(props.ride.driver);

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);

  useEffect(() => {
    getWithAuthHeader(`/vehicle/client/` + props.ride.client.id)
      .then((response) => {
        setVehicles(response.data.data);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          // setMessageAndOpenModal(error.response?.data.message, Severity.Error);
        }
      });

    getWithAuthHeader(`/driver/client/` + props.ride.client.id)
      .then((response) => {
        setDrivers(response.data.data);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          // setMessageAndOpenModal(error.response?.data.message, Severity.Error);
        }
      });

    props.setClientResponse({
      rideId: props.ride.id,
      vehicleId: props.ride.vehicle?.id,
      driverId: props.ride.driver?.id,
    });

    // eslint-disable-next-line
  }, []);

  const handleVehicleOptionChange = (value: number) => {
    const vehicleLocal = vehicles.filter(
      (vehicle) => vehicle.id === Number(value)
    )[0];

    if (vehicleLocal.id !== vehicle?.id) {
      setVehicle(vehicleLocal);
      setDriver(vehicleLocal.driver ? vehicleLocal.driver : undefined);
      props.setClientResponse({
        rideId: props.ride.id,
        vehicleId: vehicleLocal.id,
        driverId: vehicleLocal.driver ? vehicleLocal.driver.id : driver?.id,
      });
    }
  };

  const handleDriverOptionChange = (value: number) => {
    const driverLocal = drivers.filter(
      (driver) => driver.id === Number(value)
    )[0];

    if (driverLocal.id !== driver?.id) {
      setDriver(driverLocal);
      props.setClientResponse({
        rideId: props.ride.id,
        vehicleId: vehicle?.id,
        driverId: driverLocal.id,
      });
    }
  };

  const buildVehicleLabel = (vehicle: Vehicle) => {
    return (
      vehicle.make +
      " " +
      vehicle.model +
      "\n" +
      vehicle.licencePlate +
      " (" +
      vehicle.vehicleType +
      ", P: " +
      vehicle.maxPeople +
      ", L: " +
      vehicle.maxLuggage +
      ")"
    );
  };

  var vehicleOptions = vehicles.map((vehicle) => ({
    value: vehicle.id ? vehicle.id : 0,
    label: buildVehicleLabel(vehicle),
  }));

  var vehicleOption = vehicle && {
    value: vehicle.id ? vehicle.id : 0,
    label: buildVehicleLabel(vehicle),
  };

  const buildDriverLabel = (driver: Driver) => {
    return driver.firstName + " " + driver.lastName;
  };

  var driverOptions = drivers.map((driver) => ({
    value: driver.id ? driver.id : 0,
    label: buildDriverLabel(driver),
  }));

  var driverOption = driver && {
    value: driver.id ? driver.id : 0,
    label: buildDriverLabel(driver),
  };

  return (
    <div>
      <div className="div-container">
        <div className="div-content">
          {!props.showOnlyClientDetails ? (
            <>
              <div>
                <p>
                  Date: <b>{formattedTimestamp(props.ride.date)}</b> / Time:{" "}
                  <b>{formattedTime(props.ride.hour, props.ride.minute)}</b>
                </p>
              </div>
              <div>
                <p>
                  From: <b>{props.ride.destinationFrom.location_name}</b>
                </p>
              </div>
              <div>
                <p>
                  To: <b>{props.ride.destinationTo.location_name}</b>
                </p>
              </div>
              <div>
                <p>
                  Price: <b>{props.ride.passengers[0].priceEuro} €</b>
                </p>
              </div>
              {
                (props.ride.flightNo !== null 
                && props.ride.flightNo !== undefined
                  && props.ride.flightNo !== "") && 
                <div>
                  <p>
                    Flight No.: <b>{props.ride.flightNo}</b>
                  </p>
                </div>
              }

              <div>
                <label className="bookatrip">
                  <b>VEHICLE</b>
                </label>
                <SelectValue
                  options={vehicleOptions}
                  onSelectChange={(e) => handleVehicleOptionChange(e)}
                  initOption={vehicleOption}
                  isDisabled={props.ride.status === "COMPLETED"}
                />
              </div>
              <div>
                <label className="bookatrip">
                  <b>DRIVER</b>
                </label>
                {
                  <SelectValue
                    options={driverOptions}
                    onSelectChange={(e) => handleDriverOptionChange(e)}
                    initOption={driverOption}
                    isDisabled={props.ride.status === "COMPLETED"}
                  />
                }
              </div>
            </>
          ) : (
            <></>
          )}
          <div>
            Passengers:
            <ul>
              {props.ride.passengers &&
                props.ride.passengers.map((passenger) => {
                  return (
                    <li key={passenger.id}>
                      {passenger.firstName} {passenger.lastName} (L:
                      {passenger.luggage}, P:{passenger.people}, Status:
                      {passenger.status}, Payment Status:{" "}
                      {passenger.paymentStatus}){" "}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RideItem;
