import "./Button.css";

type ButtonProps = {
  type?:
    | "submit"
    | "warning"
    | "confirm"
    | "delete"
    | "ride"
    | "decline"
    | "accept";
  onClick?: () => void;
  children: React.ReactNode;
  style?: any;
  customClassName?: string;
};

const Button: React.FC<ButtonProps> = ({
  type = "submit",
  onClick,
  children,
  style,
  customClassName,
}) => {
  let buttonClass = "button";

  // Determine the button style based on the "type" prop
  if (type === "confirm") {
    buttonClass += " confirm-button";
  } else if (type === "delete") {
    buttonClass += " delete-button";
  } else if (type === "warning") {
    buttonClass += " warning-button";
  } else if (type === "ride") {
    buttonClass += " ride-button";
  } else if (type === "accept") {
    buttonClass += " accept-button";
  } else if (type === "decline") {
    buttonClass += " decline-button";
  }

  return (
    <button
      className={`${buttonClass} ${customClassName ? customClassName : ""}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  );
};

export default Button;
