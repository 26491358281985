// App.tsx
import { getMessageContent } from "@/components/Account/accountUtilities";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { getWithAuthHeader } from "@/utils/apiUtils";
import { Location, Tariff } from "../Types/Tariff";
import TariffList from "./Tariff/TariffList";
import LocationList from "./Location/List";

const APPEND_URL = "/client";

const typeTariffs = 'tariffs';
const typeLocations = 'locations';

const App: React.FC = () => {
  
  const [message, setMessage] = useState<Message | null>(null);
  const [tariffs, setTariffs] = useState<Tariff[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  }

  const refresh = (type: string) => {
    getWithAuthHeader(`${APPEND_URL}/${type}`)
    .then((response) => {
      console.log(response.data.data);
      if (type === typeTariffs) {
        setTariffs(response.data.data);
      }
      if (type === typeLocations) {
        setLocations(response.data.data);
      }
    })
    .catch((error: unknown)=>{
      if (error instanceof AxiosError) {
          setMessage({ message: error.response?.data.message, severity: Severity.Error });
        }
    });
  }

  useEffect(() => {
      refresh(typeTariffs);
      refresh(typeLocations);
    // eslint-disable-next-line
    }, []);

    const refreshTariffs = (refreshAll: boolean, removeId?: number) => {
      if (refreshAll) {
        refresh(typeTariffs);
      } else if (removeId) {
        setTariffs(tariffs.filter((tariff)=>tariff.id !== removeId));
      }
    }

    const refreshLocations = (refreshAll: boolean, removeId?: number) => {
      if (refreshAll) {
        refresh(typeLocations);
      } else if (removeId) {
        setLocations(locations.filter((location)=>location.id !== removeId));
      }
    }

  return (
    <div>
      {messageContent}
      <h1>Tariff Management</h1>
      <div>
      <h1 className="text-3xl font-bold">Tariffs {(tariffs && tariffs?.length > 0) && "- "+ tariffs.length}</h1>
      <TariffList items={tariffs} refresh={refreshTariffs} fromLocations={locations} toLocations={locations}/>
      <h1 className="text-3xl font-bold">Locations {(locations && locations?.length > 0) && "- "+ locations.length}</h1>
      <LocationList items={locations} refresh={refreshLocations}/>
    </div>
    </div>
  );
};

export default App;
