import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import weekday from "dayjs/plugin/weekday";
import advancedFormat from "dayjs/plugin/advancedFormat";
import Passenger from "@/components/ShareARide/Types/Passenger";

dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(advancedFormat);
export function isMobile() {
  return window.innerWidth <= 768; // Common breakpoint for mobile devices
}

export function formatTime12Hour(hour: number, minute: number) {
  // Ensure hour and minute are numbers
  if (typeof hour !== "number" || typeof minute !== "number") {
    throw new Error("Hour and minute should be numbers.");
  }

  // Validate input ranges
  if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
    throw new Error("Invalid hour or minute value.");
  }

  // Determine AM/PM
  const period = hour >= 12 ? "PM" : "AM";

  // Convert hour to 12-hour format
  const formattedHour = hour % 12 || 12;

  // Format minute with leading zero if necessary
  const formattedMinute = minute.toString().padStart(2, "0");

  // Combine into desired format
  return `${formattedHour
    .toString()
    .padStart(2, "0")}:${formattedMinute} ${period}`;
}

export function formatTime24Hour(hour: number, minute: number) {
  // Ensure hour and minute are numbers
  if (typeof hour !== "number" || typeof minute !== "number") {
    throw new Error("Hour and minute should be numbers.");
  }

  // Validate input ranges
  if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
    throw new Error("Invalid hour or minute value.");
  }

  // Format minute with leading zero if necessary
  const formattedMinute = minute.toString().padStart(2, "0");

  // Combine into desired format
  return `${hour.toString().padStart(2, "0")}:${formattedMinute}`;
}

export function formatDate(inputDate: string) {
  const formattedDate: { date: string; day: string } = {
    date: dayjs(inputDate).format("Do MMMM"),
    day: dayjs(inputDate).format("dddd"),
  };

  return formattedDate;
}

export const findPassengerOriginal = (passengers: Passenger[]) => {
  const passengerOriginal = passengers.find((passenger) => passenger.original);
  const passengerOriginalFound = passengerOriginal
    ? passengerOriginal
    : passengers[0];
  return passengerOriginalFound;
};
