import React, { ChangeEventHandler, useEffect } from 'react';
import './TariffItem.css';
import { Location, Tariff } from '../../Types/Tariff';
import SelectValue from '@/components/common/Select/SelectValue';

interface IMyProps {
  item: Tariff,
  handleInputChange: ChangeEventHandler,
  handleFromLocationChange: Function,
  handleToLocationChange: Function,
  fromLocation: Location[] | undefined,
  toLocation: Location[] | undefined
}

const TariffItem: React.FC<IMyProps> = (props:IMyProps) => {

  useEffect(() => {
      // eslint-disable-next-line
  }, []);

  var fromLocationOptions = [{value: 0, label: "Please select"}];
  var toLocationOptions = [{value: 0, label: "Please select"}];
  var initFromOption;
  var initToOption;

  var getAirportInput = (ind: boolean) => {
    return ind ? " (Airport)" : "";
  }
  
  if (props.fromLocation) {
    fromLocationOptions = [...fromLocationOptions, ...props.fromLocation.map((fromLocation)=>(
      {value: fromLocation.id ? fromLocation.id : 0,
      label: fromLocation.location_name + `${getAirportInput(fromLocation.airportInd)}`}))];
  }
  if (props.toLocation) {
    toLocationOptions = [...toLocationOptions, ...props.toLocation.map((toLocation)=>(
      {value: toLocation.id ? toLocation.id : 0,
      label: toLocation.location_name + `${getAirportInput(toLocation.airportInd)}`}))];
  }

  if (props.item.fromLocation && props.item.fromLocation.id) {
    initFromOption = {value: props.item.fromLocation.id ? props.item.fromLocation.id : 0,
      label: props.item.fromLocation.location_name + `${getAirportInput(props.item.fromLocation.airportInd)}`};
  }
  if (props.item.toLocation && props.item.toLocation.id) {
    initToOption = {value: props.item.toLocation.id ? props.item.toLocation.id : 0,
      label: props.item.toLocation.location_name + `${getAirportInput(props.item.toLocation.airportInd)}`};
  }

  return (
  <div>
    <div className="div-container">
      <div className="div-content">

      <div><p>Tariff Id: <b>{props.item.id}</b></p></div>
      <div>
        <label className="tariff">Price</label><br/>
        <input type="number" name="priceEuro" value={props.item.priceEuro} onChange={props.handleInputChange} />
        <label> €</label>
      </div>
      <div>
        <label className="tariff">Night Price (23:00- 05:00)</label><br/>
        <input type="number" name="nightPriceEuro" value={props.item.nightPriceEuro} onChange={props.handleInputChange} />
        <label> €</label>
        </div>
      <div>
        <label className="tariff"><b>FROM</b></label>
        <SelectValue options={fromLocationOptions} onSelectChange={(e) => props.handleFromLocationChange(e)} initOption={initFromOption}/>
        <label className="tariff"><b>TO</b></label>
        <SelectValue options={toLocationOptions} onSelectChange={(e) => props.handleToLocationChange(e)} initOption={initToOption}/>
      </div>
      </div>
    </div>
  </div>
)};

export default TariffItem;