import {isAuthenticated} from '@/utils/authUtils';
import Login from '@/components/LoginRegister/LoginRegister';
import { useEffect, useState } from 'react';

interface AuthenticatedProps {
    setAuth?: Function;
  }

const withAuthentication = <P extends AuthenticatedProps>(
    WrappedComponent: React.ComponentType<P>
  ) => (props: P) => {
    const [authenticated, setAuthenticated] = useState(isAuthenticated);
  
    useEffect(() => {
        if (props.setAuth !== undefined) props.setAuth(isAuthenticated());
    }, [props, authenticated]);

    if (!isAuthenticated()) {
        return <Login setAuth={setAuthenticated}/>
    }
  
    return <WrappedComponent {...props} />;
  }

  export default withAuthentication;
